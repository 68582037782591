.app-container {
  font-family: 'Arial', sans-serif;
  text-align: center;
  background-color: #003aaebd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-header {
  background-color: #003aaebd;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: center;
}

.app-body {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  font-size: 1.8em;
}
